<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="date" :label="$t('date')">
                <ElDatePicker
                  v-model="form.date"
                  class="!w-full"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="order"
                :label="$t('position')"
                @keydown.enter.prevent="submit(ruleFormRef)"
              >
                <ElInputNumber v-model="form.order" type="number" min="1" />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import 'moment/dist/locale/es'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_EXPERIENCE_DAY from '~/graphql/experience-days/mutation/createExperienceDay.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  experience: {
    type: Object,
    default: () => ({})
  },
  timeline: {
    type: Array,
    default: () => []
  }
})

const form = reactive({
  experience_uuid: props.experience.uuid,
  date: props.timeline?.length
    ? props.timeline[props.timeline.length - 1].original_date
      ? moment(props.timeline[props.timeline.length - 1].original_date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      : ''
    : '',
  order: props.timeline?.length ? props.timeline.length + 1 : 1
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)

const rules = {
  experience_uuid: { required },
  order: { required }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  experience_uuid: [
    {
      required: true,
      message: t('experience_is_required'),
      trigger: 'blur'
    }
  ],
  order: [
    {
      required: true,
      message: t('position_is_required'),
      trigger: 'blur'
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  moment.locale('es')
  loading.value = true
  const response = await mutation(CREATE_EXPERIENCE_DAY, {
    experience_uuid: form.experience_uuid,
    date: form.date ? moment(form.date).format('YYYY-MM-DD') : '',
    order: form.order
  })
  if (response.error) {
    loading.value = false
    if (
      response.error.message.includes('These date already exists') ||
      response.error.message.includes('Ya existe esa fecha')
    ) {
      $showError(t('date_already_exists'))
    } else if (response.error.message.includes('Validation failed')) {
      $showError(t('repeated_order'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createExperienceDay ?? null)
    loading.value = false
  }
}
</script>
